import "../Message.css"


import { useEffect, useRef } from "react";
import { useSignals } from "@preact/signals-react/runtime";
import { signal } from "@preact/signals-core";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import LoadingIcons from 'react-loading-icons'
import SendRounded from '@mui/icons-material/SendRounded';
import Markdown from 'react-markdown';



export const onSendMessageCallback = signal(() => {})
export const onSelectContainerCallback = signal(() => {})
export const onUnsetContainerCallback = signal(() => {})
export const onNextPageClickCallback = signal(() => {})
export const doDisableNextPageButton = signal(true)
export const onPrevPageClickCallback = signal(() => {})
export const doDisableSend = signal(true)
export const messages = signal([])
export const selectedContainer = signal(null)
export const doShowServerStatus = signal(false)
export const serverStatusText = signal("Server disconnected")


const inputValue = signal("")
const sendMessage = () => {
    if (doDisableSend.value) return
    if (inputValue.value.trim() === "") return
    onSendMessageCallback.value(inputValue.value)
    inputValue.value = ""
}



function AiChat() {

    useSignals()

    return (
        <div className={`flex flex-col items-center justify-center h-full w-full col-span-full row-span-full`}>

            <div 
                className={`w-full h-full flex justify-center overflow-y-scroll bg-[#e8e8e8]`} 
                style={{
                    // background: "linear-gradient(135deg, #FFFFFF, #A3DDFF)",
                }}
            >
                <div className={`h-max w-full flex flex-col gap-1.5 max-w-2xl mt-10 mb-10`}>
                    {
                        messages.value.map((message, index) => {

                            // Check if message above is same type, container and ai should be treated as same type
                            var isSameType = false
                            if (index > 0) {
                                var thisType = message.type
                                var lastType = messages.value[index - 1].type
                                if (thisType === "product") thisType = "ai"
                                if (lastType === "product") lastType = "ai"
                                if (lastType === "action_string") lastType = "ai"
                                if (thisType === "action_string") thisType = "ai"
                                if (thisType === lastType) {
                                    isSameType = true
                                }
                            }

                            if (message.type === "ai") {
                                return (
                                        <div key={index} className={`message px-4 py-2 shadow-md bg-[#389CD6] border border-[#3189BD] text-white rounded-3xl mr-auto ml-3 break-words w-max ${isSameType ? "" : "mt-4 rounded-tl-none"}`} style={{maxWidth: "80%"}}>
                                            {/* if message is "" */}
                                            {
                                                message.content === "" ? (
                                                    <LoadingIcons.Puff stroke="#fff"/>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <Markdown>{message.content}</Markdown>
                                            {/* Invisible element at the end of the messages */}
                                        </div>
                                )
                            }
                            if (message.type === "user") {
                                return (
                                        <div key={index} className={`message px-4 py-2 shadow-md bg-white border border-[#D9D9D9] text-[#8C8C8C] rounded-3xl ml-auto mr-3 break-words w-max ${isSameType ? "" : "mt-4 rounded-br-none"}`} style={{maxWidth: "80%"}}>
                                            <Markdown>{message.content}</Markdown>
                                            {/* Invisible element at the end of the messages */}
                                        </div>
                                )
                            }
                            if (message.type === "product") {

                                const isAvailable = message.data.context.prices.length > 0

                                return (
                                        <div
                                            key={index}
                                            className={`flex flex-col px-4 py-4 shadow-md text-white rounded-3xl bg-[#389CD6] mr-auto ml-3 break-words w-max 
                                            ${message.data.product.slug === selectedContainer.value?.product.slug ? " border-4 border-[#66C032]" : "border border-[#3189BD]"}
                                            ${isSameType ? "" : "mt-4 rounded-tl-none"}`}
                                            style={{maxWidth: "80%"}}
                                        >
                                            {
                                                message.data.product.slug === selectedContainer.value?.product.slug ? (
                                                    <div className={` bg-[#66C032] w-fit text-white flex flex-row items-center rounded-full px-2 py-1 shadow-md mb-2 gap-2`}>
                                                        <CheckCircleIcon />Ausgewählt
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`mb-2 flex flex-col`}>
                                                <span className={`text-sm text-[#D4EFFF]`}>
                                                    Vorschlag
                                                </span>
                                                <span className={` text-white text-lg font-bold`}>
                                                    {
                                                        message.data.product.name
                                                    }
                                                </span>
                                            </div>
                                            <div className={`mb-5`}>
                                                <p className={`mb-2 flex flex-row items-center gap-2 text-[#D4EFFF]`}>
                                                    <DoneIcon />Das darf rein             
                                                </p>
                                                <div className={`flex flex-wrap gap-1.5`}>
                                                    {
                                                        message.data.product.items_included.map((item, index) => {
                                                            return (
                                                                <div className={`border border-[#5CC3FF] bg-[#3FAFEF] rounded-full px-2`} key={index}>
                                                                    <p className="">{item}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <p className={`mb-2 flex flex-row items-center gap-2 text-[#D4EFFF]`}>
                                                    <CloseIcon />Das darf nicht rein             
                                                </p>
                                                <div className={`flex flex-wrap gap-1.5`}>
                                                    {
                                                        message.data.product.items_excluded.map((item, index) => {
                                                            return (
                                                                <div className={`border border-[#5CC3FF] bg-[#3FAFEF] rounded-full px-2`} key={index}>
                                                                    <p className="">{item}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {message.data.product.slug !== selectedContainer.value?.product.slug ? (<div 
                                                className={`w-full text-center py-3 px-3 text-white text-lg rounded-xl mt-5 ${isAvailable ? "hover:shadow-2xl shadow-md cursor-pointer font-semibold bg-[#66C032] border border-[#76D43F]" : "italic text-sm bg-[#2A77A388] border border-[#61A6CF88] text-[#cdecff]"} duration-200`}
                                                onClick={() => {if (isAvailable) {onSelectContainerCallback.value(message.data)}}}
                                            >
                                                {isAvailable ? "Diesen Container wählen" : "Dieser Container ist leider nicht in Ihrer Region verfügbar."}
                                            </div>) : (<></>)}
                                            {/* Invisible element at the end of the messages */}
                                        </div>
                                )
                            } else if (message.type === "action_string") {
                                const action_string = message.content

                                const arrayFromString = (str) => {
                                    const result = [];
                                    let currentCount = 0;
                                
                                    for (const char of str) {
                                        if (char === '-') {
                                            currentCount++;
                                        } else if (char === 'o') {
                                            if (currentCount > 0) {
                                                result.push({ type: "thought", length: currentCount });
                                                currentCount = 0;
                                            }
                                            result.push({ type: "action" });
                                        }
                                    }
                                
                                    // For trailing thoughts without a following action
                                    if (currentCount > 0) {
                                        result.push({ type: "thought", length: currentCount });
                                    }
                                
                                    return result;
                                };

                                const thoughtElem = (length, isLast) => (
                                    <div
                                        // Impulse if isLast
                                        className={`h-1 bg-white rounded-full duration-75 ${isLast ? "animate-pulse" : ""}`}
                                        style={{ width: `${20 + length * 0.5}px` }}
                                    ></div>
                                )

                                const actionElem = (isLast) => (
                                    <div
                                        className={`h-[15px] w-[15px] rounded-full bg-white duration-75 ${isLast ? "animate-pulse" : ""}`}
                                    ></div>
                                )

                                const actionArray = arrayFromString(action_string)

                                const elemArray = actionArray.map((elem, index) => {
                                    if (elem.type === "thought") {
                                        return thoughtElem(elem.length, index === actionArray.length - 1)
                                    } else if (elem.type === "action") {
                                        return actionElem(index === actionArray.length - 1)
                                    }
                                })

                                return (
                                    <>
                                    <div className={`message px-4 py-2 shadow-md bg-[#0E5882] border border-[#3189BD] text-white rounded-3xl mr-auto ml-3 break-words w-max ${isSameType ? "" : "mt-4"}`} style={{maxWidth: "80%"}}>
                                        <div className="overflow-x-hidden flex flex-row justify-end mb-3"><div className="flex flex-row items-center gap-2 w-max">{elemArray}</div></div>
                                    </div>
                                    <div className={`message px-4 py-2 shadow-md bg-[#0E5882] border border-[#3189BD] text-white rounded-3xl mr-auto ml-3 break-words w-max`} style={{maxWidth: "80%"}}>
                                        <p className=" text-sm opacity-75 italic"><strong>Hinweis:</strong> Der Denkprozess kann länger dauern, wenn Sie ein spezielleres Anliegen haben. Wir arbeiten stetig daran, diesen Prozess zu optimieren. Unsere Priorität ist es immer, Ihnen die bestmögliche Lösung anzubieten.</p>
                                    </div>
                                    </>
                                )
                            }
                        })
                    }
                </div>
            </div>

            <div 
                className={`mt-auto flex flex-col items-center justify-center w-full border-t border-t-[#D9D9D9]`}
                style={{
                    boxShadow: "#fffa 0 50px 50px 50px"
                }}
            >
                {
                    doShowServerStatus.value ? (
                        <div className={`bg-[#8C8C8C] w-full flex items-center justify-center py-1 text-sm text-white`}>{serverStatusText.value}</div>
                    ) : (
                        <></>
                    )
                }
                {
                    selectedContainer.value ? (
                        <div className={`bg-[#3FAFEF] w-full flex flex-row items-center gap-2`}>
                            <div className={`max-w-2xl mx-auto w-full flex flex-row items-center gap-2 px-6`}>
                                <div className={`py-2 w-9/12 flex flex-col`}>
                                    <div className={`text-white text-sm`}>
                                        Ausgewählter Container
                                    </div>
                                    <div className={`text-white text-xl truncate`}>
                                        {selectedContainer.value.product.name}
                                    </div>
                                </div>
                                <CloseIcon className={`ml-auto text-white cursor-pointer`} onClick={() => {onUnsetContainerCallback.value()}} />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
                <div 
                    className={`w-full flex justify-center items-center`}
                >
                    <div className={`w-full max-w-2xl h-14 flex flex-row items-center justify-center px-6 gap-5 mb-2`}>
                        <input
                            autofocus 
                            type="text"
                            className={`text-lg w-full outline-none ${doDisableSend.value ? "opacity-50" : ""}`}
                            placeholder="Nachricht senden..."
                            onChange={(e) => {inputValue.value = e.target.value}}
                            value={inputValue.value}
                            onSubmit={() => {sendMessage()}}
                            onKeyDown={(e) => {if (e.key === "Enter") {sendMessage()}}}
                        />
                        <SendRounded 
                            className={`
                                ${!doDisableSend.value && inputValue.value?.trim() !== "" ? "text-[#3FAFEF]" : "text-[#8C8C8C]"} 
                                ${doDisableSend.value ? "opacity-50" : ""}
                            `} 
                            style={{fontSize: "2rem"}}
                            onClick={() => {sendMessage()}}
                        />
                    </div>
                </div>

                <div className={`mb-2 flex flex-row gap-1`}>
                    <div 
                        className={`h-full aspect-square text-white rounded-full shadow-md flex flex-row items-center justify-center gap-1 bg-[#8C8C8C] cursor-pointer`}
                        onClick={() => {onPrevPageClickCallback.value()}}
                    >
                        <span className="max-lg:hidden flex items-center justify-center"><ArrowBackIcon className={`text-white`} /></span>
                        <span className="lg:hidden flex items-center justify-center"><ArrowBackIcon fontSize="small" className={`text-white`} /></span>
                    </div>
                    <div
                        className={`min-w-fit max-lg:text-sm max-lg:px-2 max-lg:py-1 px-4 py-2 ${doDisableNextPageButton.value ? "bg-[#8C8C8C] opacity-50" : "bg-[#3FAFEF]"} text-white rounded-full shadow-md flex flex-row items-center justify-center gap-1 ${doDisableNextPageButton.value ? "cursor-default" : "cursor-pointer"}`}
                        onClick={() => {if (!doDisableNextPageButton.value) {onNextPageClickCallback.value()} }}
                    >
                        Größe auswählen 
                        <span className="lg:hidden"><ArrowForwardIcon fontSize="small"/></span> 
                        <span className="max-lg:hidden"><ArrowForwardIcon/></span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AiChat;