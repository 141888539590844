// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message p {
    margin-bottom: 1rem;
}

.message ol, .message ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
}

.message ol {
    list-style-type: decimal;
}

.message *:last-child {
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/Message.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".message p {\n    margin-bottom: 1rem;\n}\n\n.message ol, .message ul {\n    list-style-type: disc;\n    list-style-position: inside;\n    margin-bottom: 1rem;\n}\n\n.message ol {\n    list-style-type: decimal;\n}\n\n.message *:last-child {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
