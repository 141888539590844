import AiChat from "./AiChat";
import ChooseSize from "./ChooseSize";
import EnterZip from "./EnterZip";
import PubPriv from "./PubPriv";



import { progress } from "./Progress";
import { useSignals } from "@preact/signals-react/runtime";



const progressSlides = [
    <EnterZip />,
    <AiChat />,
    <ChooseSize />,
    <PubPriv />,
]



function Body() {

    useSignals()

    return (
        <div className={`grid grid-cols-1 grid-rows-1 h-[calc(100%_-_8.5rem)] max-lg:h-[calc(100%_-_7rem)]`}>
            {progressSlides[progress.value]}
        </div>
    );
}

export default Body;