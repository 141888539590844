import axios from 'axios'
import { io } from "socket.io-client";

import {
    default as ShopAiCommunicator,
} from "./modules/ShopAiCommunicator-v0.2"

import {
  progress as                       progressIndex
} from './components/Progress';
import { 
  onNextPageClickCallback as        zipOnNext,
  onValidZipEnteredCallback as      zipOnValid,
  zipInputValue as                  zipValue,
  validZip as                       zipValidValue,
  doDisableNextPageButton as        zipDisableNext,
} from './components/EnterZip';
import AiChat, { 
  onPrevPageClickCallback as        chatOnPrev,
  onNextPageClickCallback as        chatOnNext, 
  onSendMessageCallback as          chatOnSend, 
  onSelectContainerCallback as      chatOnSelect, 
  onUnsetContainerCallback as       chatOnUnset, 
  doDisableNextPageButton as        chatDisableNext,
  doDisableSend as                  chatDisableSend,
  messages as                       chatMessages,
  selectedContainer as              chatValue,
  doShowServerStatus as             chatShowStatus,
  serverStatusText as               chatStatusText,
  messages,
} from './components/AiChat';
import {
  onPrevPageClickCallback as        sizeOnPrev,
  onNextPageClickCallback as        sizeOnNext,
  prices as                         sizePrices,
  selectedPrice as                  sizeValue,
  doDisableNextPageButton as        sizeDisableNext,
  onSelectedPriceChangeCallback as  sizeOnChange,
} from './components/ChooseSize';
import {
  onOrderButtonClickCallback as     pubOnOrder,
  isPublicValue as                  pubValue,
  onPrevPageClickCallback as        pubOnPrev,
} from './components/PubPriv';

export const main = async () => {
    // Set all signals to their default values
    progressIndex.value = 0 // Start at the first page
    zipValue.value = "" // Start with an empty zip code
    chatValue.value = null // Start with no selected container
    sizeValue.value = "" // Start with no selected size
    pubValue.value = null // Start with no public/private value

    zipDisableNext.value = true
    chatDisableNext.value = true
    sizeDisableNext.value = true

    var communicator = null
    const setupCommunicator = async (chat_token, zipcode) => {
        // ShopAiCommunicator instance
        communicator = new ShopAiCommunicator({
            endpoint: process.env.REACT_APP_WS_URL ?? '127.0.0.1:3000',
            chatToken: await requestNewChatToken(zipcode),
        })
        chatOnSend.value = (message) => {
            communicator.sendMessage(message)
        }
        communicator.setHistoryEventCallback((history) => {
            messages.value = JSON.parse(history)
        })
        communicator.setIsConnectedChangeCallback((isConnected) => {
            zipDisableNext.value = !isConnected
            chatDisableSend.value = !isConnected
            if (isConnected) {
                communicator.getHistory()
            }
        })
        communicator.setSessionStatusChangeCallback((status) => {
            if (status === "operational") {
                zipDisableNext.value = false
                chatDisableSend.value = false
            } else if (status === "processing") {
                zipDisableNext.value = true
                chatDisableSend.value = true
            }
        })
        communicator.setTokenEventCallback((data) => {
            addStringTokenToLastAiMessage(data)
        })
    }

    // Zip handler
    zipOnValid.value = async (zip) => {
        zipDisableNext.value = true
        const token = await requestNewChatToken(zip)
        if (token !== null) {
            saveChatTokenToCookies(`${token}@${zip}`)
            if (communicator) {
                communicator.disconnect()
            }
            await setupCommunicator(token, zip)
        }
    }
    zipOnNext.value = () => {
        progressIndex.value = 1
    }

    // Chat handler
    chatOnPrev.value = () => {
        progressIndex.value = 0
    }
    chatOnNext.value = () => {
        progressIndex.value = 2
    }
    chatOnSelect.value = (container) => {
        chatValue.value = container
        sizePrices.value = container.context.prices
        sizeValue.value = null
        sizeDisableNext.value = true
        chatDisableNext.value = false
    }
    chatOnUnset.value = () => {
        chatValue.value = null
        sizePrices.value = null
        chatDisableNext.value = true
    }

    // Size handler
    sizeOnPrev.value = () => {
        progressIndex.value = 1
    }
    sizeOnNext.value = () => {
        progressIndex.value = 3
    }
    sizeOnChange.value = (size) => {
        sizeValue.value = size
        sizeDisableNext.value = false
    }

    pubOnPrev.value = () => {
        progressIndex.value = 2
    }
    pubOnOrder.value = () => {
        const urlFunc = (slug, size, zipcode, isPublic) => `https://app.entsorgo.de/rechner/container/quick-order?zipcode=${zipcode}&size=${size}&location=${isPublic ? "public" : "private"}&material=${slug}`

        const slug = chatValue.value.product.slug
        const size = sizeValue.value
        const zipcode = zipValidValue.value
        const isPublic = pubValue.value

        const url = urlFunc(slug, size, zipcode, isPublic)

        console.log(url);
        window.open(url, '_blank')
    }
        
    /*
    var token = loadChatTokenFromCookies()
    if (token !== null) {
        const tokenPart = token.split('@')[0]
        const zipPart = token.split('@')[1]
        if (tokenPart === "" || zipPart === "") {
            token = null
            return
        }

        zipValue.value = zipPart
        zipValidValue.value = zipPart

        await setupCommunicator(tokenPart, zipPart)
        progressIndex.value = 1
    }
    */

}

function loadChatTokenFromCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('chat_token=')) {
            return cookie.substring('chat_token='.length, cookie.length);
        }
    }
    return null;
}

function saveChatTokenToCookies(chatToken) {
    document.cookie = `chat_token=${chatToken}; path=/`;
}

async function requestNewChatToken(zipCode) {
    const httpUrl = process.env.REACT_APP_HTTP_URL ?? 'http://127.0.0.1:3000'
    const licenseKey = "abc"
    const lang = "de"
    try {
        const response = await axios.get(`${httpUrl}/init_session?zipcode=${zipCode}&license_key=${licenseKey}&lang=${lang}`)
        if (response.status !== 200) {
            throw new Error("Failed to request chat token")
        }
        return response.data.chat_token
    } catch (error) {
        console.error(error)
        return null
    }
}

function handleActionString (actionString) {
    let current_messages = chatMessages.value
    current_messages = current_messages.filter((msg) => {
        return msg.type !== "action_string"
    })
    current_messages = [...current_messages, {"type": "action_string", "content": actionString}]
    chatMessages.value = current_messages
}

const addStringTokenToLastAiMessage = (token) => {
    // Check if theres an "type": "ai" message which contains a "isTmp": "true". If so, add the token to this messages existing "message" string. if not, create a new message with "isTmp": "true" and "message": token
    var copy = messages.value 
    const lastAiMessage = copy.find((msg) => {
        return msg.type === "ai" && msg.isTmp === true
    })
    if (lastAiMessage === undefined) {
        copy = [...copy, {"type": "ai", "isTmp": true, "content": token}]
    } else {
        lastAiMessage.content = lastAiMessage.content + token
    }

    // move all "isTmp": "true" messages to the end of the array
    var tmpArray = copy
    tmpArray = tmpArray.filter((msg) => {
        return msg.isTmp !== true
    })
    tmpArray = [...tmpArray, ...copy.filter((msg) => {
        return msg.isTmp === true
    })]

    messages.value = [...tmpArray]
}