import { useSignals } from "@preact/signals-react/runtime";


import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { signal } from "@preact/signals-core";
import { useEffect } from "react";




export const prices = signal(null)
export const selectedPrice = signal(null)
export const onSelectedPriceChangeCallback = signal(() => {})
export const doDisableNextPageButton = signal(true)
export const onNextPageClickCallback = signal(() => {})
export const onPrevPageClickCallback = signal(() => {})




function ChooseSize() {

    useSignals()

    useEffect(() => {
        // sort sizes by size
        prices.value.sort((a, b) => {
            return a.size - b.size
        })
    }, [prices.value])


    
    
    return (
        <div className={`flex flex-col items-center justify-center h-full w-full col-span-full row-span-full`}>
                <div className={`w-full my-auto`}>
                    <div className={`w-full flex flex-col items-center justify-center`}>
                        <p className={`mt-auto text-2xl mb-5 w-4/5 text-center text-[#3FAFEF]`}>
                            Wie groß soll der Container sein?
                        </p>
                        <p className={`mb-10 w-4/5 text-center text-[#8C8C8C]`}>
                            Bitte wählen Sie das gewünschte Volumen des Containers aus.
                        </p>
                    </div>
                    <div className={`flex flex-col gap-3 max-w-lg items-center max-h-[40vh] mx-auto overflow-y-scroll`}>
                        {
                            prices.value.map((item, index) => {
                                return (
                                    <>
                                        <div 
                                            className={`flex flex-row gap-5 px-10 w-full justify-between cursor-pointer`}
                                            onClick={() => {onSelectedPriceChangeCallback.value(item.size)}}
                                            key={index}
                                        >
                                            <div className="flex flex-row justify-center items-center gap-8">
                                                
                                                {
                                                    selectedPrice.value !== item.size ? (
                                                        <RadioButtonUncheckedIcon className="text-[#8C8C8C]" />
                                                    ) : (
                                                        <RadioButtonCheckedIcon className="text-[#3FAFEF]" />
                                                    )
                                                }

                                                <div className={`text-xl text-[#444444] font-bold`}>
                                                    {item.size} m³
                                                </div>
                                            </div>
                                            <div className={`text-xl text-[#3FAFEF]`}>{item.price} €</div>
                                        </div>
                                        {index <= prices.value.length -2 ? (
                                            <div className="px-10 w-full"><div className="w-full border-b border-b-[#D9D9D9]"></div></div>
                                        ) : (<></>)}
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={`mb-2 flex flex-row gap-1`}>
                    <div 
                        className={`h-full aspect-square text-white rounded-full shadow-md flex flex-row items-center justify-center gap-1 bg-[#8C8C8C] cursor-pointer`}
                        onClick={() => {onPrevPageClickCallback.value()}}
                    >
                        <span className="max-lg:hidden flex items-center justify-center"><ArrowBackIcon className={`text-white`} /></span>
                        <span className="lg:hidden flex items-center justify-center"><ArrowBackIcon fontSize="small" className={`text-white`} /></span>
                    </div>
                    <div 
                        className={`min-w-fit max-lg:text-sm max-lg:px-2 max-lg:py-1 px-4 py-2 ${doDisableNextPageButton.value === true ? "bg-[#8C8C8C] opacity-50" : "bg-[#3FAFEF]"} text-white rounded-full shadow-md flex flex-row items-center justify-center gap-1 ${doDisableNextPageButton.value === true ? "cursor-default" : "cursor-pointer"}`}
                        onClick={doDisableNextPageButton.value === true ? () => {} : () => {onNextPageClickCallback.value()}}
                    >
                        Weiter
                        <span className="lg:hidden"><ArrowForwardIcon fontSize="small"/></span> 
                        <span className="max-lg:hidden"><ArrowForwardIcon/></span>
                    </div>
                </div>
        </div>
    );
}

export default ChooseSize;