import { signal } from "@preact/signals-core";
import { useSignals } from "@preact/signals-react/runtime";
import LinkIcon from '@mui/icons-material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



export const isPublicValue = signal(null)
export const onPrevPageClickCallback = signal(() => {})
export const onOrderButtonClickCallback = signal(() => {})


function PubPriv() {

    useSignals()

    return (
        <div className={`flex flex-col items-center justify-center h-full w-full col-span-full row-span-full`}>
            <p className={`mt-auto text-2xl mb-5 w-4/5 text-center text-[#3FAFEF]`}>
                Öffentlicher oder privater Stellplatz?
            </p>
            <p className={`mb-10 w-4/5 max-w-[700px] text-center text-[#8C8C8C]`}>
                Bevor Du einen Container bestellst, entscheide, wo er stehen soll. Auf privatem Grundstück brauchst Du nur genug Platz fürs Containerfahrzeug (3 Meter Breite, 3,50 Meter Höhe). Für öffentliche Plätze wie Gehwege, Parkplätze oder Straßenränder ist eine Genehmigung vom Ordnungsamt nötig. Die zuständige Behörde hängt von der Größe Deiner Ortschaft ab. Unsere Website bietet spezifische Informationen für verschiedene Standorte.
            </p>

            <div className={`flex flex-row mb-auto border-2 border-[#3FAFEF] bg-[#3FAFEF] rounded-full overflow-hidden shadow-md`}>
                <div 
                    className={`w-[120px] text-center px-4 py-2 ${isPublicValue.value !== null ? (isPublicValue.value ? "text-[#3FAFEF] bg-white 3FAFEF" : "text-white bg-[#3FAFEF]") : "text-[#3FAFEF] bg-white"} cursor-pointer`}
                    onClick={() => {isPublicValue.value = false}}
                >Privat</div>
                <div className={`h-full bg-[#3FAFEF]`} style={{width: "2px"}}></div>
                <div 
                    className={`w-[120px] text-center px-4 py-2 ${isPublicValue.value !== null ? (!isPublicValue.value ? "text-[#3FAFEF] bg-white 3FAFEF" : "text-white bg-[#3FAFEF]") : "text-[#3FAFEF] bg-white"} cursor-pointer`}
                    onClick={() => {isPublicValue.value = true}}
                >Öffentlich</div>
            </div>

            <div className={`mb-2 flex flex-row gap-1`}>
                <div 
                    className={`h-full aspect-square text-white rounded-full shadow-md flex flex-row items-center justify-center gap-1 bg-[#8C8C8C] cursor-pointer`}
                    onClick={() => {onPrevPageClickCallback.value()}}
                >
                    <span className="max-lg:hidden flex items-center justify-center"><ArrowBackIcon className={`text-white`} /></span>
                    <span className="lg:hidden flex items-center justify-center"><ArrowBackIcon fontSize="small" className={`text-white`} /></span>
                </div>
                <div 
                    className={`min-w-fit max-lg:text-sm max-lg:px-2 max-lg:py-1 px-4 py-2 ${isPublicValue.value === null ? "bg-[#8C8C8C] opacity-50" : "bg-[#3FAFEF]"} text-white rounded-full shadow-md flex flex-row items-center justify-center gap-1 ${isPublicValue.value === null ? "cursor-default" : "cursor-pointer"}`}
                    onClick={() => {
                        if (isPublicValue.value === null) return
                        onOrderButtonClickCallback.value()
                    }}
                >
                    <span className="lg:hidden"><LinkIcon fontSize="small"/></span> 
                    <span className="max-lg:hidden"><LinkIcon/></span>
                    Auf entsorgo.de fortfahren
                </div>
            </div>
        </div>
    );
}

export default PubPriv;