import { signal } from '@preact/signals-core';
import { useSignals } from '@preact/signals-react/runtime';



export const progress = signal(0)


const progress_array = [
    "Postleitzahl angeben",
    "Container wählen",
    "Größe wählen",
    "Ort"
]



function Progress() {

    useSignals()

    return (
        <div className={`h-14 flex flex-col items-center gap-1 justify-center bg-white z-50 relative`}>
            <div className={`flex flex-row gap-2 items-center justify-center`}>
                {progress_array.map((item, index) => {
                    return (
                        <div key={index} className={`h-2 w-2 bg-[#3FAFEF] rounded-full ${index != progress.value ? "opacity-25" : ""}`}></div>
                    )
                })}
            </div>
            <div className={`text-center text-[#3FAFEF] text-sm`}>
                {progress_array[progress.value]}
            </div>
        </div>
    );
}

export default Progress;