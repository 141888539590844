import { io } from "socket.io-client";


class ShopAiCommunicator {

    #onIsConnectedChangeCallback
    #onSessionStatusChangeCallback
    #onHistoryEventCallback
    #onTokenEventCallback
    #onProductSuggestionEventCallback

    #sendMessageCallback
    #getHistoryCallback
    #disconnectCallback

    constructor({
        endpoint,
        chatToken,
    }) {

        const socket = io(endpoint, {
            reconnectionDelayMax: 10000,
            autoConnect: true,
            reconnection: true,
            query: {
                "chat_token": chatToken
            }
        })

        this.sessionStatus = null;
        this.isConnected = false;

        socket.on("connect", () => {
            this.#setIsConnected(socket.connected)
        })

        socket.on("connect_error", (error) => {
            this.#setIsConnected(socket.connected)
        })

        socket.on("disconnect", (reason) => {
            this.#setIsConnected(socket.connected)
        })

        socket.io.on("error", (error) => {
            this.#setIsConnected(socket.connected)
        });

        socket.io.on("reconnect", (attempt) => {
            this.#setIsConnected(socket.connected)
        });

        socket.io.on("reconnect_attempt", (attempt) => {
            this.#setIsConnected(socket.connected)
        });

        socket.io.on("reconnect_error", (error) => {
            this.#setIsConnected(socket.connected)
        });

        socket.io.on("reconnect_failed", () => {
            this.#setIsConnected(socket.connected)
        });

        socket.onAny((event, ...args) => {
            this.#handleEvent(event, ...args)
        });

        // Set up actions
        this.#sendMessageCallback = (message) => {
            socket.emit("send_message", message)
        }
        this.#getHistoryCallback = () => {
            socket.emit("get_history")
        }
        this.#disconnectCallback = () => {
            socket.close()
        }
    }

    disconnect() {
        if (this.#disconnectCallback) {
            this.#disconnectCallback()
        } else {
            console.error("No disconnect callback set")
        }
    }

    // IS CONNECTED
    #setIsConnected(isConnected) {
        if (this.isConnected === isConnected) {
            return
        }
        this.isConnected = isConnected
        this.#onIsConnectedChange()
    }
    #onIsConnectedChange() {
        if (this.#onIsConnectedChangeCallback) {
            this.#onIsConnectedChangeCallback(this.isConnected)
        }
    }
    setIsConnectedChangeCallback(callback) {
        this.#onIsConnectedChangeCallback = callback
    }

    // SESSION STATUS
    #setSessionStatus(status) {
        if (this.sessionStatus === status) {
            return
        }
        this.sessionStatus = status
        this.#onSessionStatusChange()
    }
    #onSessionStatusChange() {
        if (this.#onSessionStatusChangeCallback) {
            this.#onSessionStatusChangeCallback(this.sessionStatus)
        }
    }
    setSessionStatusChangeCallback(callback) {
        this.#onSessionStatusChangeCallback = callback
    }

    // HISTORY
    #onHistoryEvent(data) {
        if (this.#onHistoryEventCallback) {
            this.#onHistoryEventCallback(data)
        }
    }
    setHistoryEventCallback(callback) {
        this.#onHistoryEventCallback = callback
    }

    // TOKEN
    #onTokenEvent(data) {
        if (this.#onTokenEventCallback) {
            this.#onTokenEventCallback(data)
        }
    }
    setTokenEventCallback(callback) {
        this.#onTokenEventCallback = callback
    }

    // PRODUCT SUGGESTION
    #onProductSuggestionEvent(data) {
        if (this.#onProductSuggestionEventCallback) {
            this.#onProductSuggestionEventCallback(data)
        }
    }
    setProductSuggestionEventCallback(callback) {
        this.#onProductSuggestionEventCallback = callback
    }

    // ACTIONS
    sendMessage(message) {
        if (this.isConnected === false) {
            console.error("Cannot send message, not connected")
            return
        }
        if (this.#sendMessageCallback) {
            this.#sendMessageCallback(message)
        }
    }
    getHistory() {
        if (this.isConnected === false) {
            console.error("Cannot get history, not connected")
            return
        }
        if (this.#getHistoryCallback) {
            this.#getHistoryCallback()
        }
    }

    #handleEvent(event, data) {
        switch (event) {
            case "status":
                this.#setSessionStatus(data.toLowerCase())
                break
            case "history":
                this.#onHistoryEvent(data)
                break
            case "token":
                this.#onTokenEvent(data)
                break
            case "product_suggestion":
                this.#onProductSuggestionEvent(data)
                break
            default:
                console.error("Unknown event", event)
        }
    }
}


export default ShopAiCommunicator;