import logo from "../logo.png"



function Header() {
    return (
        <div 
            className={`h-20 max-lg:h-14 flex flex-row justify-center items-center relative z-50 border-b border-b-[#D9D9D9]`}
            style={{
                boxShadow: "#fff8 0 -50px 50px 50px"
            }}
        >
            <img src={logo} className={`h-10 max-lg:h-8`} alt="logo" />
        </div>
    );
}

export default Header;