import { signal } from "@preact/signals-core";
import { useSignals } from "@preact/signals-react/runtime";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



export const zipInputValue = signal("")
export const validZip = signal("")
export const onValidZipEnteredCallback = signal(() => {})
export const onNextPageClickCallback = signal(() => {})
export const doDisableNextPageButton = signal(true)



function EnterZip() {

    useSignals()

    function removeNonNumericChars(str) {
        return str.replace(/[^0-9]/g, '')
    }

    function isZipValid(zip) {
        return zip.length === 5
    }

    return (
        <div className={`flex flex-col items-center justify-center h-full w-full col-span-full row-span-full`}>
            <p className={`mt-auto text-2xl mb-5 w-4/5 text-center text-[#3FAFEF]`}>
                Wo soll der Container gestellt werden?
            </p>
            <p className={`mb-10 w-4/5 text-center text-[#8C8C8C]`}>
                Bitte geben Sie die Postleitzahl von dem Ort ein, an dem Sie den Container stellen möchten.
            </p>
            <input 
                value={zipInputValue.value} 
                onChange={(e) => {
                    zipInputValue.value = removeNonNumericChars(e.target.value)
                    if (isZipValid(zipInputValue.value)) {
                        onValidZipEnteredCallback.value(zipInputValue.value)
                        validZip.value = zipInputValue.value
                    }
                }}
                onKeyDown={(e) => {if (e.key === "Enter" && isZipValid(zipInputValue.value) && !doDisableNextPageButton.value) {onNextPageClickCallback.value()}}}
                type="text" 
                placeholder="Postleitzahl" 
                className={`placeholder-[#8C8C8C] outline-none text-center border-2 p-2 rounded-full text-xl bg-white shadow-md ${!isZipValid(zipInputValue.value) ? "border-[#8C8C8C]" : "border-[#3FAFEF]"}`} 
                maxLength={5} 
                required
            />
            <div
                className={`max-lg:text-sm max-lg:px-2 max-lg:py-1 px-4 py-2 ${doDisableNextPageButton.value ? "bg-[#8C8C8C] opacity-50" : "bg-[#3FAFEF]"} text-white rounded-full shadow-md mt-auto mb-2 flex flex-row items-center justify-center gap-1 cursor-pointer ${doDisableNextPageButton.value ? "cursor-default" : "cursor-pointer"}`}
                onClick={() => {if (!doDisableNextPageButton.value) {onNextPageClickCallback.value()}}}
            >
                Container auswählen 
                <span className="lg:hidden"><ArrowForwardIcon fontSize="small"/></span> 
                <span className="max-lg:hidden"><ArrowForwardIcon/></span>
            </div>
        </div>
    );
}

export default EnterZip;