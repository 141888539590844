import { useSignals } from '@preact/signals-react/runtime';
import { useState, useEffect } from 'react';

import { main } from './main';

import Header from './components/Header';
import Body from './components/Body';
import Progress from './components/Progress';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

main()

function App() {
  useSignals()
  const { height, width } = useWindowDimensions();
  return (
    <div style={{height: `${height}px`}} className="App w-screen">
      <Header />
      <Body />
      <Progress />
    </div>
  );
}



export default App;